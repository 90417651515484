import React from 'react';
import Vitrina from '../components/vitrina';
import { Helmet } from 'react-helmet';


let imgVitrina = {
  // bgImg:'vitrina-niveles.png',
  bgImg:'vitrina-2.png',
  alt:'PlayHouse Montessori'
};
const Informate = () => {
  return (
    <>
      <Helmet>
        <title>Niveles | PlayHouse Montessori</title>
      </Helmet>
      <Vitrina 
        alt={imgVitrina.alt}
        bgImg={imgVitrina.bgImg}
      />
      <div className="container-fluid mb-5">
        <div className="row">
          <div className="col-7 col-sm-5 col-md-3 col-xl-2 mx-auto cont-subTitle">
            <h2 className="text-1 bold text-center mb-0">Niveles</h2>
          </div>
        </div>
      </div>
      <div className="container-fluid cont-niveles py-5">
        <div className="row">
          <div className="pattern pattern-top"></div>
          <div className="col-12">
            <div className="row">
              <div className="col-12 col-md-6 bgc-pink">
                <div className="info-niveles">
                  <h2 className="text-1 bold title">CASA DE NIÑOS<br/>
                    <p className="text-3 semiBold pb-2">3 a 6 añoSSSs</p>
                  </h2>
                  <p className="text-4 regular">
                    Los primeros años de vida son un periodo de gran transformación, esta etapa de desarrollo se extiende desde el nacimiento hasta los seis años de edad. En este tiempo los niños se vuelven funcionales e independientes; ellos aprenden a controlar su movimiento a y a trabajar con sus manos. Utilizan sus sentidos para absorber cada aspecto de su ambiente, su lenguaje y cultura, y en el proceso construyen su intelecto.
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6 img-niveles-casa-ninos d-flex justify-content-center align-content-center align-items-center">
              </div>
            </div>
          </div>
          <div className="pattern pattern-bottom d-none d-md-inline-block"></div>
          <div className="col-12">
            <div className="row">
              <div className="col-12 col-md-6 img-niveles-taller d-flex justify-content-center align-content-center align-items-center">
              </div>
              <div className="col-12 col-md-6 bgc-green-dark">
                <div className="info-niveles">
                  <h2 className="text-1 bold title">TALLER<br/>
                    <p className="text-3 semiBold pb-2">6 a 12-13 años</p>
                  </h2>
                  <p className="text-4 regular">
                    El curriculum de primaria ofrece una continuidad que se construye sobre la experiencia adquirida en el preescolar. El ambiente pone de manifiesto una nueva etapa de desarrollo que ofrece la integración de las artes, ciencias, geografía, historia y lenguaje evocan a la imaginación innata y a la abstracción en el niño en primaria.<br/>El lenguaje científico propone el estudio amplio de la zoología, botánica, antropología, geografía, geología, etc. se introduce, exponiendo al niño de manera acertada, se organiza la información y respeta la inteligencia y el interés del mismo.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="pattern pattern-bottom"></div>
        </div>
      </div>
      <div className="container-fluid my-md-5 my-0">
        <div className="row">
          <div className="col-12 col-md-8 mx-auto text-center mt-4 mb-md-5 ">
            <h1 className="text-1 light uppercase c-blue mb-4">Educación Cósmica</h1>
            <h2 className="text-4 regular c-grey">
              La presentación del conocimiento se hace como parte de una narrativa a grande escala que desencadena el origen de la tierra, la vida, las comunidades humanas, y la historia moderna siempre en el contexto de un todo de la vida. Ello provee una visión inspiradora, organizada e integradora en las “Grandes Lecciones”.
            </h2>
          </div>
        </div>
      </div>
      <div className="container-fluid my-5 cont-jornadas">
        <div className="row">
          <div className="col-11 col-sm-9 col-md-7 col-lg-6 col-xl-5 mx-auto text-center mt-5 mb-5 box-jornadas">
            <h3 className="text-1 bold c-blue mb-md-4 mb-0">Horario y Jornadas</h3>
            <div className="row ">
              <div className="col-6 col-sm-6 text-left">
                <ul className="p-0 mb-0">
                  <li className="text-2 c-pink bold uppercase mb-0">Casa de niños</li>
                  <li className="c-grey text regular">Jornada mañana</li>
                  <li className="c-grey text regular">Jornada tarde</li>
                  <li className="c-grey text regular mb-2">Jornada completa</li>
                  <li className="text-2 c-pink bold uppercase mb-0">Taller</li>
                  <li className="c-grey text regular">Jornada única completa</li>
                </ul>
              </div>
              <div className="col-6  col-sm-6 text-right">
                <ul className="p-0 mb-0">
                  <li className="text-2 c-pink bold uppercase mb-0">Horarios</li>
                  <li className="c-blue text bold">08:00 a 12:00 hrs</li>
                  <li className="c-blue text bold">14:00 a 18:00 hrs</li>
                  <li className="c-blue text bold mb-2">08:00 a 18:00 hrs</li>
                  <li className="text-2 c-white bold uppercase mb-0">&nbsp;</li>
                  <li className="c-blue text bold">08:00 a 16:00 hrs</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Informate
