// import ScriptTag from 'react-script-tag';



export const contenidoGaleria = [
  {
    title:'Titulo imagen',
    subtitle:'SubTitulo imagen',
    bgImg:'1'
  },
  {
    title:'Titulo imagen',
    subtitle:'SubTitulo imagen',
    bgImg:'2'
  },
  {
    title:'Titulo imagen',
    subtitle:'SubTitulo imagen',
    bgImg:'3'
  },
  {
    title:'Titulo imagen',
    subtitle:'SubTitulo imagen',
    bgImg:'4'
  },
  {
    title:'Titulo imagen',
    subtitle:'SubTitulo imagen',
    bgImg:'5'
  },
  {
    title:'Titulo imagen',
    subtitle:'SubTitulo imagen',
    bgImg:'6'
  },
  {
    title:'Titulo imagen',
    subtitle:'SubTitulo imagen',
    bgImg:'7'
  },
  {
    title:'Titulo imagen',
    subtitle:'SubTitulo imagen',
    bgImg:'8'
  },
  {
    title:'Titulo imagen',
    subtitle:'SubTitulo imagen',
    bgImg:'9'
  },
  {
    title:'Titulo imagen',
    subtitle:'SubTitulo imagen',
    bgImg:'10'
  }

];
export default {contenidoGaleria}



