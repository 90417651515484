import React from 'react'
import { Helmet } from 'react-helmet';
import Vitrina from '../components/vitrina'

let imgVitrina = {
  // bgImg:'vitrina-quienes-somos.png',
  bgImg:'vitrina-3.png',
  alt:'Conocenos PlayHouse Montessori'
};
const QuienesSomos = () => {
  return (
    <>
    <Helmet>
      <title>Quienes Somos | PlayHouse Montessori</title>
    </Helmet>
      <Vitrina 
        alt={imgVitrina.alt}
        bgImg={imgVitrina.bgImg}
      />
      <div className="container-fluid mb-5">
        <div className="row">
          <div className="col-11 col-sm-9 col-md-8 col-xl-6 mx-auto cont-subTitle">
            <h1 className="text-1 light text-center">Sobre Nosotros</h1>
            <h2 className="text-4 c-grey text-center">En febrero del 2019, se inaugura Play House Montessori, en Melipilla. <br/>Con todo la ilusión y el deseo de dar una alternativa de educación respetuosa, consciente y que brinde a los niños espacios y experiencias significativas.</h2>
          </div>
        </div>
      </div>
      <div className="container-fluid cont-mision-vision">
        <div className="row">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6 bgc-pink">
                <div className="info-mv">
                  <h2 className="text-1 bold title c-white text-center">
                    Misión
                  </h2>
                  <img 
                    src={require('../assets/icons/dots.svg')}
                    alt="" 
                  />
                  <p className="text-4 regular c-white text-center">
                    Propiciar el desarrollo libre de niños y niñas, en un ambiente cálido, acogedor y armónico, 	donde sean respetados cada uno como seres únicos e individuales. Creando conciencia de la 	importancia del cuidado y respeto al planeta.	
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6 bgc-green-dark">
                <div className="info-mv">
                  <h2 className="text-1 bold title c-white text-center">
                    Visión
                  </h2>
                  <img 
                    src={require('../assets/icons/dots.svg')}
                    alt="" 
                  />
                  <p className="text-4 regular c-white text-center">
                    Que nuestros niños y niñas conviertan nuestra sociedad en una más respetuosa y solidaria, 	menos individualista, compuesta por personas libres y felices. Comprometidas con el cuidado 	de nuestro planeta y todo lo que lo compone.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid my-5">
        <div className="row">
          <div className="col-12 col-md-8 mx-auto text-center mt-5 mb-4">
            <h2 className="text-1 light uppercase c-blue mb-5">Nuestros Principios</h2>
            <img 
              src={require('../assets/icons/dots.svg')}
              alt="" 
            />
          </div>
          <div className="container mb-5">
            <div className="row">
              <div className="col-12 my-2 box-principios">
                <div className="box-principios row justify-content-md-center justify-content-between align-content-center align-items-center flex-md-nowrap ">
                  <img 
                    className="mx-auto"
                    src={require('../assets/quienes-somos/libertad.png')}
                    alt="" 
                  />
                  <p className="c-grey text-4 light px-3 mt-3">
                    <b className="c-blue text-4 bold">Libertad con límites:</b> Los niños son libres de elegir entre alternativas dadas, nada en la escuela es impuesto u obligado, sin embargo, hay límites que enmarcan la libertad.
                  </p>
                </div>
              </div>
              <div className="line-dots col-11 mx-auto my-0 my-md-2"></div>
              <div className="col-12 my-2 box-principios">
              <div className="box-principios row justify-content-md-center justify-content-between align-content-center align-items-center flex-md-nowrap ">
                  <img 
                    className="mx-auto"
                    src={require('../assets/quienes-somos/responsabilidad.png')}
                    alt="" 
                  />
                  <p className="c-grey text-4 light px-3 mt-3">
                    <b className="c-blue text-4 bold">Responsabilidad:</b> Nuestra metodología está enfocada en entregar herramientas que permita a los niños y niñas desenvolverse en el futuro como adulto. Es por eso que la responsabilidad es un principio muy importante como herramienta tal.
                  </p>
                </div>
              </div>
              <div className="line-dots col-11 mx-auto my-0 my-md-2"></div>
              <div className="col-12 my-2 box-principios">
              <div className="box-principios row justify-content-md-center justify-content-between align-content-center align-items-center flex-md-nowrap ">
                  <img 
                    className="mx-auto"
                    src={require('../assets/quienes-somos/compromiso.png')}
                    alt="" 
                  />
                  <p className="c-grey text-4 light px-3 mt-3">
                    <b className="c-blue text-4 bold">Compromiso:</b> Realizar actividades grupales, trabajar en equipo y ser solidario son habilidades blandas que permitirá a nuestros estudiantes hacer de nuestra sociedad una mejor.
                  </p>
                </div>
              </div>
              <div className="line-dots col-11 mx-auto my-0 my-md-2"></div>
              <div className="col-12 my-2 box-principios">
              <div className="box-principios row justify-content-md-center justify-content-between align-content-center align-items-center flex-md-nowrap ">
                  <img 
                    className="mx-auto"
                    src={require('../assets/quienes-somos/solidaridad.png')}
                    alt="" 
                  />
                  <p className="c-grey text-4 light px-3 mt-3">
                    <b className="c-blue text-4 bold">Solidaridad:</b> La solidaridad para nuestra escuela no está enfocada sólo en ayudas materiales hacia los más necesitados, eso es sólo una arista. La empatía permite que seamos seres solidarios con otros, acompañado, entendiendo, escuchando, apoyando, eso también es solidaridad.
                  </p>
                </div>
              </div>
              <div className="line-dots col-11 mx-auto my-0 my-md-2"></div>
              <div className="col-12 my-2 box-principios">
                <div className="box-principios row justify-content-md-center justify-content-between align-content-center align-items-center flex-md-nowrap ">
                  <img 
                    className="mx-auto"
                    src={require('../assets/quienes-somos/convivencia.png')}
                    alt="" 
                  />
                  <p className="c-grey text-4 light px-3 mt-3">
                    <b className="c-blue text-4 bold">Convivencia:</b> Vivir en comunidad es una herramienta social necesaria, saber convivir con otros, respetando diferentes opiniones, religiones, creencias, ideologías, entre otras; permitirá a nuestros estudiantes aprender a convivir de manera respetuosa y tolerante.
                  </p>
                </div>
              </div>
              <div className="line-dots col-11 mx-auto my-0 my-md-2"></div>
              <div className="col-12 my-2 box-principios">
                <div className="box-principios row justify-content-md-center justify-content-between align-content-center align-items-center flex-md-nowrap ">
                  <img 
                    className="mx-auto"
                    src={require('../assets/quienes-somos/respeto.png')}
                    alt="" 
                  />
                  <p className="c-grey text-4 light px-3 mt-3">
                    <b className="c-blue text-4 bold">Respeto al ser:</b> Cada uno poseemos una esencia que nos hace seres únicos e irrepetibles, en esa premisa nuestros niños y niñas podrán respetarse a sí mismos y a los demás; poniendo límites cuando sean necesario y respetando los límites de los otros.
                  </p>
                </div>
              </div>
              <div className="line-dots col-11 mx-auto my-0 my-md-2"></div>
              <div className="col-12 my-2 box-principios">
                <div className="box-principios row justify-content-md-center justify-content-between align-content-center align-items-center flex-md-nowrap ">
                  <img 
                    className="mx-auto"
                    src={require('../assets/quienes-somos/planeta.png')}
                    alt="" 
                  />
                  <p className="c-grey text-4 light px-3 mt-3">
                    <b className="c-blue text-4 bold">Respeto al planeta:</b> Nuestro planeta está sufriendo importantes cambios que cada vez le hacen más daño, por falta de conciencia social, respecto a uso de cada uno de los bienes que la tierra nos proporciona (agua, flora, fauna), en nuestros estudiantes queremos generar esta conciencia, que permita salvar el planeta y respetar sus componentes.
                  </p>
                </div>
              </div>

            </div>
          </div>
          <div className="col-12 col-md-8 mx-auto text-center mt-5 mb-md-4">
              <h2 className="text-1 light uppercase c-blue mb-md-5">Nuestro Equipo</h2>
              <img 
                src={require('../assets/icons/dots.svg')}
                alt="" 
              />
          </div>
          <div className="col-12 col-sm-10 col-md-9 col-lg-8 col-xl-6 mx-auto text-center mt-5 mb-4 box-equipo">
            <div className="row justify-content-between align-content-center align-items-center">
              <div className="col-12 col-md-3">
                <div className="cont-img mx-auto d-flex justify-content-center align-content-center align-items-center">
                  <img 
                    src={require('../assets/quienes-somos/equipo/loreto.png')}
                    alt="" 
                  />
                </div>
              </div>
              <div className="col-12 col-md-9 cont-info">
                <p className="text-center text-md-left text-3 c-blue regular">Loreto Pérez Tapia</p>
                <p className="text-center text-md-left text-4 c-yellow semiBold">Directora</p>
                <p className="text-center text-md-left c-grey light">Licenciada en Educación, Educadora de Párvulos, Psicopedagoga, Educadora Montessori, Diplomado en Neurociencias, Cursos de Crianza Respetuosa, inteligencia emocional, entre otros.</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-10 mx-auto text-center mt-5 mb-4 box-equipo">
            <div className="row justify-content-center">
              <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-5">
                <div className="cont-img mx-auto d-flex justify-content-center align-content-center align-items-center">
                  <img 
                    src={require('../assets/quienes-somos/equipo/katherine.png')}
                    alt="" 
                  />
                </div>
                <div className="cont-info text-center">
                  <p className="text-center text-3 c-blue regular">Katherine Pérez Tapia</p>
                  <p className="text-center text-4 c-yellow semiBold">Guía de comunidad infantil</p>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-5">
                <div className="cont-img mx-auto d-flex justify-content-center align-content-center align-items-center">
                  <img 
                    src={require('../assets/quienes-somos/equipo/yusmary.png')}
                    alt="" 
                  />
                </div>
                <div className="cont-info text-center">
                  <p className="text-center text-3 c-blue regular">Yusmary Alviarez Melgarejo</p>
                  <p className="text-center text-4 c-yellow semiBold">Guía de casa de niños</p>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-5">
                <div className="cont-img mx-auto d-flex justify-content-center align-content-center align-items-center">
                  <img 
                    src={require('../assets/quienes-somos/equipo/barbara.png')}
                    alt="" 
                  />
                </div>
                <div className="cont-info text-center">
                  <p className="text-center text-3 c-blue regular">Bárbara Galdames Silva</p>
                  <p className="text-center text-4 c-yellow semiBold">Guía de Taller</p>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-5">
                <div className="cont-img mx-auto d-flex justify-content-center align-content-center align-items-center">
                  <img 
                    src={require('../assets/quienes-somos/equipo/javier.png')}
                    alt="" 
                  />
                </div>
                <div className="cont-info text-center">
                  <p className="text-center text-3 c-blue regular">Javier Gumera Ponce</p>
                  <p className="text-center text-4 c-yellow semiBold">Guía del taller de inglés</p>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-5">
                <div className="cont-img mx-auto d-flex justify-content-center align-content-center align-items-center">
                  <img 
                    src={require('../assets/quienes-somos/equipo/deysy.png')}
                    alt="" 
                  />
                </div>
                <div className="cont-info text-center">
                  <p className="text-center text-3 c-blue regular">Deysy Marín Gómez</p>
                  <p className="text-center text-4 c-yellow semiBold">Auxiliar de servicios menores</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default QuienesSomos
