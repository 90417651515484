import React, {useState, useEffect} from 'react';
import {
  // Link, 
  NavLink, 
  withRouter
} 
from 'react-router-dom';
//import {Helmet} from 'react-helmet';
import gsap from 'gsap';

let tl = gsap.timeline();
const openMenu = () => {
  tl.to('.menu-mobile',0,{
    css: { display:'block' }
  }).to('body',0,{
    css: { overflow:'hidden' }
  }).to('.menu-mobile',1,{
    right:'0',
    ease:'expo.inOut'
  }).to('.menuBurger span', .6,{
    delay:-1,
    // scaleX:0,
    // transformOrigin: "50% 0%",
    ease:'expo.inOut'
  }).to('.menuBurger-close', .6,{
    scaleX:1,
    delay:-0.6,
    ease:'expo.inOut'
  }).to('.menuBurger-close', .6,{
    // scaleX:0,
    delay:-1,
    css:{
      display:'block'
    }
  });

};
const closeMenu = () => {
  tl.to(".menu-mobile", 1, {
    right: '-100%',
    ease: "expo.inOut"
  }).to(".menuBurger-close", .6, {
    delay:-1,
    // scaleX:0,
    ease: "expo.inOut"
  }).to(".menuBurger", .6, {
    delay: -0.6,
    // scaleX: 1,
    // transformOrigin: "50% 0%",
    ease: "expo.inOut"
  }).to(".menuBurger-close", .6, {
    delay: -1,
    css: { display: 'none' }
  }).to("body", 0, { css: { overflow: "auto" } })
  .to(".menu-mobile", 0, {
    css: { display: "none" }
  });
};

const Header = ({history}) => {
  const [menuState, setMenuState] = useState({ menuOpened: false });
  useEffect(() => {

    history.listen(() => { 
      setMenuState({ menuOpened: false });
    });

    if(menuState.menuOpened === true) {
      openMenu();
    } else if (menuState.menuOpened === false) {
      closeMenu();
    }
  });
  return (
    <header className="header container-fluid">
      
      <nav className="d-flex justify-content-between align-content-center align-items-center">
          <div className="col-6 col-sm-3">
            <NavLink to="/" exact className="logo">
              <img 
                src={require('../assets/logo-play-house.png')}
                alt=""
              />
            </NavLink>
          </div>
          <div className="col-6 text-right cont-menuBurger">
            <div className="menuBurger ml-auto" 
            onClick={() => setMenuState({menuOpened: true})}>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
          <div className="col-9 menu align-items-center align-content-center">
              <div className="col-12">
                  <div className="row">
                      <div className="col-10 d-flex justify-content-around align-content-center align-items-center">
                        <NavLink activeClassName="active" to="/" exact>Home</NavLink>
                        <NavLink activeClassName="active" to="/metodologia">Metodología</NavLink>
                        <NavLink activeClassName="active" to="/niveles" >Niveles</NavLink>
                        <NavLink activeClassName="active" to="/quienes-somos" >Quienes Somos</NavLink>
                        <NavLink activeClassName="active" to="/galeria" >Galería</NavLink>
                        
                        {/* <Link to="/" exact>Home</Link>
                        <Link to="/metodologia">Metodología</Link>
                        <Link to="/niveles" >Niveles</Link>
                        <Link to="/quienes-somos" >Quienes Somos</Link> */}
                        {/* <NavLink to="/galeria" exact>Galería</NavLink> */}
                      </div>
                      <div className="col-2 rrss d-flex justify-content-end">
                        <a rel="noopener noreferrer" href="https://www.instagram.com/montessorimelipilla/" target="_blank">
                          <img 
                            src={require('../assets/icons/i-instagram.svg')}
                            alt="instagram,"
                          />
                        </a>
                        <a rel="noopener noreferrer" href="https://www.facebook.com/PlayHouseMontessoriMelipilla" target="_blank">
                          <img 
                            src={require('../assets/icons/i-facebook.svg')}
                            alt="facebook"/>
                        </a>
                      </div>
                  </div>
              </div>
          </div>
          <div className="menu-mobile d-flex justify-content-center flex-column align-items-center align-content-center">
            <div 
              className="menuBurger-close" 
              onClick={() => setMenuState({menuOpened: false})}>
              <span></span>
              <span></span>
            </div>
            <NavLink to="/" exact className="logo text-center mb-4">
              <img 
                src={require('../assets/logo-play-house.png')}
                alt=""
              />
            </NavLink>
            <NavLink className="c-blue text-1 my-2" to="/" exact>Home</NavLink>
            <NavLink className="c-blue text-1 my-2" to="/metodologia">Metodología</NavLink>
            <NavLink className="c-blue text-1 my-2" to="/niveles">Niveles</NavLink>
            <NavLink className="c-blue text-1 my-2" to="/quienes-somos">Quienes Somos</NavLink>
            <NavLink className="c-blue text-1 my-2" to="/galeria">Galería</NavLink>
            <div className="rrss mt-3 d-flex justify-content-center align-items-center align-content-center">
              <a rel="noopener noreferrer" href="https://www.instagram.com/montessorimelipilla/" target="_blank" >
                <img 
                  src={require('../assets/icons/i-instagram.svg')}
                  alt="instagram,"
                />
              </a>
              <a rel="noopener noreferrer" href="https://www.facebook.com/PlayHouseMontessoriMelipilla" target="_blank" >
                <img 
                  src={require('../assets/icons/i-facebook.svg')}
                  alt="facebook"/>
              </a>
            </div>
          </div>
      </nav>
  </header>
  )
};

export default withRouter(Header);
