// import React from 'react';
import React, {useState} from 'react';
import { useForm } from 'react-hook-form';
import emailjs from 'emailjs-com';

const Form = () => {
  
  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur"
  });
  const frmContact = { 
    userName:'', 
    userEmail:'', 
    userPhone:'', 
    emailDetails:'' 
  };
  const [contact,setContact] = useState(frmContact);
  const [showMessage, setShowMessage] = useState(false);
  const handleChange = data => { 
    const {name,value} = data.target;
    setContact({...contact,[name]:value}); 
  };
  function onSubmit (data) {
    // data.preventDefault();
    emailjs.send('default_service','template_n550d7f', contact, 'user_b72htgyTWSzmoaHqFj2pY')
    .then((response) => {
      console.log('SUCCESS!', response.status, response.text);
      setContact(frmContact);
      setShowMessage(true);
    }, (err) => {
      console.log('FAILED...', err);
    });
    
  };

  // const frmContact = { 
  //   userName:'', 
  //   userEmail:'', 
  //   userPhone:'', 
  //   emailDetails:'' 
  // };
  // const [contact,setContact] = useState(frmContact);
  // const [showMessage, setShowMessage] = useState(false);
  // const handleChange = e => { 
  //   const {name,value} = e.target;
  //   setContact({...contact,[name]:value}); 
  // };
  // const handleSubmit = e =>{
  //   e.preventDefault();
  //   emailjs.send('default_service','template_n550d7f', contact, 'user_b72htgyTWSzmoaHqFj2pY')
  //   .then((response) => {
  //          console.log('SUCCESS!', response.status, response.text);
  //          setContact(frmContact);
  //          setShowMessage(true);
  //   }, (err) => {
  //          console.log('FAILED...', err);
  //   });
  // };
  return (
    <form className="row" onSubmit={handleSubmit(onSubmit)}>
      <div className="col-12 col-md-6">
        <div className="row">
          <div className="col-12">
            <input 
              value={contact.userName}
              onChange={handleChange} 
              type="text" 
              placeholder="Nombre" 
              name="userName" 
              ref={
                register({
                  required: true, 
                  
                })} 
              
              />
            <span className="text-error">
              {errors.userName && errors.userName.message}
            </span>
          </div>
          <div className="col-12">
            <input 
              value={contact.userEmail}
              onChange={handleChange} type="text" 
              placeholder="Email" 
              name="userEmail" 
              ref={register({
                required: true,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Email inválido"
                }
              })}
            />
            <span className="text-error">
              {errors.userEmail && errors.userEmail.message}
            </span>
          </div>
          <div className="col-12">
            <input 
            
              value={contact.userPhone}
              onChange={handleChange} 
              type="tel" 
              placeholder="Teléfono" 
              name="userPhone" 
              ref={
                register({
                  required: true, 
                  minLength: 6, 
                  maxLength: 12
                  })} />
            <span className="text-error">
              {errors.userPhone && errors.userPhone.message}
            </span>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-6">
        <div className="row">
          <div className="col-12">
            <textarea 
              onChange={handleChange} 
              placeholder="Mensaje" 
              name="emailDetails" 
              ref={register({
                max: 30, 
                min: 5, 
                maxLength: 400
                })} />

          </div>
          <div className="col-12 text-left">
            <input type="submit" className="button-form" />
          </div>
        </div>
      </div>
      { showMessage ? <div className="alert alert-success col-md-12 mx-auto text-center bgc-blue c-white text-3 semiBold " role="alert">Ya recibimos tu mensaje, pronto te contactaremos!</div> : ``}
    </form>
  );
}
export default Form