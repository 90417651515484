import React from 'react';
import Vitrina from '../components/vitrina';
import { Helmet } from 'react-helmet';

// import $ from 'jquery';

let imgVitrina = {
  bgImg:'vitrina-metodologia.png',
  alt:'PlayHouse Metodo Montessori'
};

const Metodologia = () => {
  return (
    <>
      <Helmet>
        <title>Metodología | PlayHouse Montessori</title>
      </Helmet>
      <Vitrina
        alt={imgVitrina.alt}
        bgImg={imgVitrina.bgImg}
      />
      <div className="container-fluid mb-5">
        <div className="row">
          <div className="col-8 col-sm-7 col-md-6 col-xl-4 mx-auto cont-subTitle">
            <h1 className="text-1 bold text-center mb-0">Método Montessori</h1>
          </div>
        </div>
      </div>
      <div className="container-fluid info-metodologia py-md-5">
        <div className="row">
          <div className="container">
            <div className="col-12 text-justify">
              <p className="text-4 light mb-5">
                El método Montessori, es un método científico, enfocado al desarrollo natural del ser humano. Fue creado por María Montessori en el año 1907, en Italia y se ha expandido por el mundo con excelentes resultados académicos y Humanos.
              </p>
              <p className="text-4 light mb-5">
                Este proyecto educativo está enfocado principalmente en el respeto a la individualidad de cada menor y a sus ritmos en el aprendizaje. Por lo que no pretendemos formar niños y niñas excelentes académicamente hablando, la educación Montessori se basa en entregar educación para la vida, dando herramientas que fortalezcan las habilidades blandas del ser humano.
              </p>
              <p className="text-3 regular mb-5 c-blue">
                Creemos en el potencial individual de cada estudiante y no esperamos que cada niño sea una copia de otro, si no que cada uno sea la mejor versión de sí mismo, basándose en sus 7 principios que sostiene este proyecto educativo institucional.
              </p>
              <p className="text-4 light mb-5">
                Hablamos de Montessori como una filosofía que propone una visión del hombre, de su desarrollo y de valores que sustentan el proceso educativo. Esencialmente, los principios que están en la base de nuestro enfoque son: libertad, responsabilidad y autonomía, proponiendo a través de estos que el centro de la tarea educativa sea la formación de la conciencia del ser humano.
              </p>
              <p className="text-4 light mb-5">
                Entendemos conciencia como la facultad de estar presentes, el “darse cuenta” de la realidad interior y también del mundo que nos rodea. Hablamos de desarrollar la conciencia personal, ambiental, cultural y planetaria. Y es que solo desde este tipo de crecimiento será posible apelar al cultivo de valores como reciprocidad, interdependencia y paz, instalándolos como una forma de conducta y convivencia.<br/>El centro de la inspiración filosófica de María Montessori radica en la idea anterior. De ahí que en nuestro currículum se plasman una serie de actividades y lecciones que apuntan al desarrollo de esta facultad. Por lo anterior, además, es que la visión de educación Montessori cobra gran relevancia en el contexto social, ético y valórico de nuestra cultura, aportando al desarrollo de seres humanos capaces de comprender y aportar a las complejidades del mundo contemporáneo.
              </p>
            </div>
          </div>
        </div>
      </div>  
    </>
  )
}

export default Metodologia