import React from 'react'
// import {contenidoGaleria} from '../components/contenidoGaleria';
import VariableWidth from '../components/slide';
 import { Helmet } from 'react-helmet';
 import Vitrina from '../components/vitrina';
// import Slider from "react-slick";
let imgVitrina = {
  // bgImg:'vitrina-home.png',
  bgImg:'vitrina-4.png',
  alt:'Galería PlayHouse Montessori'
};
// console.log(info)
class Galeria extends React.Component {
  render(){
  return (
    <>
      <Vitrina 
        bgImg = {imgVitrina.bgImg}
        alt = {imgVitrina.alt}
      />
      <div className="container-fluid mb-5">
        <div className="row">
          <div className="col-8 col-sm-7 col-md-6 col-xl-4 mx-auto cont-subTitle">
            <h1 className="text-1 bold text-center mb-0">Galería PlayHouse</h1>
          </div>
        </div>
      </div>
      <div className="container-fluid cont-box-gallery">
        <div className="row">

          {/* <div className="pattern pattern-top"></div> */}
          <div className="container-fluid">
            <VariableWidth />
            <Helmet>
              <title>Galería | PlayHouse Montessori</title>
              <link
                rel="stylesheet"
                type="text/css"
                charset="UTF-8"
                href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
              />
              <link
                rel="stylesheet"
                type="text/css"
                href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
              />
            </Helmet>
            
          {/* <div className="pattern pattern-bottom"></div> */}
          </div>
        </div>
      </div>
    
    </>
  )
}
}

export default Galeria
// {contenidoGaleria.map((contenidoItem) => (
//   <section className="item">
//     <div className="item__img-wrap">
//       <div className="item__img item__img--t1">
//         <div className="img-fluid col-md-4 col-12" stye={{backgroundImage:{imgSrc}}}></div>
//         <img className="img-fluid col-md-4 col-12"
//           src={require('../assets/galeria/img-1.jpeg')}
//           alt={contenidoItem.title}
//         />
//         <img className="img-fluid col-md-4 col-12"
//           src={require(`../assets/galeria/img-${contenidoItem.imgSrc}.jpeg`)}
//           alt={contenidoItem.title}
//         />
//       </div>
//     </div>
//     <div className="item__caption">
//       <h2 className="item__caption-title">{contenidoItem.title}</h2>
//       <p className="item__caption-copy">{contenidoItem.subtitle}</p>
//     </div>
    
//   </section>
// ))}