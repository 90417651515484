import React from 'react';
// import React, {useState} from 'react'
// import { NavLink } from 'react-router-dom';
// import axios from 'axios';
// import emailjs from 'emailjs-com';
// import $ from 'jquery';
import Form from '../components/form';





const Footer = () => {
//   const frmContact = { 
//     userName:'', 
//     userEmail:'', 
//     userPhone:'', 
//     emailDetails:'' 
//   };
//   const [contact,setContact] = useState(frmContact);
//   const [showMessage, setShowMessage] = useState(false);
//   const handleChange = e => { 
//     const {name,value} = e.target;
//     setContact({...contact,[name]:value}); 
//   };
//   const handleSubmit = e =>{
//     e.preventDefault();
     
//     emailjs.send('default_service','template_n550d7f', contact, 'user_b72htgyTWSzmoaHqFj2pY')
//     .then((response) => {
//            console.log('SUCCESS!', response.status, response.text);
//            setContact(frmContact);
//            setShowMessage(true);
//     }, (err) => {
//            console.log('FAILED...', err);
//     });
//   };
// export default class Footer extends React.Component{
  
  
  
  // render(){
    
    return (
      <>
        <div className="container-fluid cont-admision">
          <div className="row">
            <div className="col-12 bg-admision d-flex justify-content-center align-items-center align-content-center">
              <div className="text-admision col-12 col-sm-9 col-md-7 col-lg-5 col-xl-4">
                <h2 className="text-1 bold text-center">
                  Admisión 
                  <span> 2021 </span>
                </h2>
                <p className="text-1 light text-center">
                  Iniciamos procesos de matrículas
                </p>
              </div>
            </div>
          </div>
        </div>
  
        <div className="container-fluid bgc-yellow">
            <div className="row">
                <div className="col-12 col-md-10 mx-auto bgc-white">
                    <div className="row">
                        <div className="col-12 col-md-7  form-contacto">
                          <p className="text-2 mb-md-5 semiBold">CONTACTANOS</p>
                            <Form/>
                        </div>
                        <div className="col-12 col-md-5 mt-5 mt-md-0 info-contacto">
                            <p className="text-2 mb-md-5  semiBold text-right uppercase">ENCUENTRANOS EN</p>
                            <div className="row">
                                <div className="col-12 mb-4 d-flex justify-content-end align-content-center align-items-center">
                                  <a className="text-4 regular" href="tel:228958300" >
                                    228958300
                                  </a>
                                  <span className="text-4 light">/</span>
                                  <a className="text-4 regular" href="tel:56995744677" >
                                    +56 9 95744677
                                  </a>
                                  <i className="i-tel"></i>
                                </div>
                                <div className="col-12 mb-4 d-flex justify-content-end align-content-center align-items-center">
                                  <a className="text-4 regular" href="mail:info@playhousemontessorimeli.cl" >
                                    info@playhousemontessorimeli.cl
                                  </a>
                                  <i className="i-mail"></i>
                                </div>
                                <div className="col-12 mb-4 d-flex justify-content-end align-content-center align-items-center">
                                  <a rel="noopener noreferrer" target="_blank" className="text-4 regular" href="https://www.facebook.com/PlayHouseMontessoriMelipilla"  >
                                    playhousemontessorimelipilla
                                  </a>
                                  <i className="i-face"></i>
                                </div>
                                <div className="col-12 mb-4 d-flex justify-content-end align-content-center align-items-center">
                                  <a rel="noopener noreferrer" target="_blank" className="text-4 regular" href="https://www.instagram.com/montessorimelipilla/"  >
                                    montessorimelipilla
                                  </a>
                                  <i className="i-insta"></i>
                                </div>
                                <div className="col-12 mb-4 d-flex justify-content-end align-content-center align-items-center">
                                  <a rel="noopener noreferrer" target="_blank" className="text-4 regular" href="https://goo.gl/maps/wMAt4qQ6BbfJGN1P6">
                                    Pardo #550, Melipilla
                                  </a>
                                  <i className="i-maps"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </>
      
    )
  // }
}
export default Footer
