import React, { Component } from "react";
import Slider from "react-slick";

import {contenidoGaleria} from '../components/contenidoGaleria';

export default class VariableWidth extends Component {
  render() {
    const settings = {
      // className: "slider ",
      dots: false,
      infinite: true,
      // centerMode: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      // variableWidth: true,
      swipeToSlide: true,
      focusOnSelect:true,
      responsive: [
        {
          breakpoint: 1255,
          settings: {
            slidesToShow: 1,
            centerMode: false,
            variableWidth: false

          }
        },{
          breakpoint: 750,
          settings: {
            slidesToShow: 1,
            centerMode: false,
            variableWidth: false

          }
        }
      ]
    };
    return (
      <div className="container-slide col-12 col-md-7 mx-auto bgc-pink">
        <Slider {...settings}>
        {contenidoGaleria.map((contenidoItem) => (

          <div className="box-image">
              <img 
                src={require(`../assets/galeria/img-${contenidoItem.bgImg}-desktop.png`)} 
                alt=""
                className="d-none d-md-inline-block"
              />
              <img 
                src={require(`../assets/galeria/img-${contenidoItem.bgImg}-mobile.png`)} 
                alt=""
                className="d-sm-none"
              />
          </div>
        ))}
        
        </Slider>
      </div>
    );
  }
}
   