import React, {useState, useEffect} from 'react';
import {Route} from 'react-router-dom';
import gsap from 'gsap';
import './styles/app.scss';

// Components
import Header from './components/header';
import Footer from './components/footer'

// pages
import Home from './pages/home'
import Metodologia from './pages/metodologia'
import Niveles from './pages/niveles'
import QuienesSomos from './pages/quienes-somos'
import Galeria from './pages/galeria'
import { Helmet } from 'react-helmet';
// import ScrollToTop from './components/scrolltop';
const routes = [
  { path: '/', name: 'Home', Component: Home},
  { path: '/metodologia', name: 'Metodologia', Component: Metodologia},
  { path: '/niveles', name: 'Niveles', Component: Niveles},
  { path: '/quienes-somos', name: 'QuienesSomos', Component: QuienesSomos},
  { path: '/galeria', name: 'Galeria', Component: Galeria}
]



function debounce(fn, ms){
  let timer;
  return () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      fn.apply(this, arguments);
    },ms);
  }
}

function App() {
  gsap.to('body',0,{css: {visibility: 'visible'}});
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });
  
  
  useEffect(() => {
    // fix inner height or Window for Mobile with *vh
    let vh = dimensions.height * 0.01;
    // var scss *--vh
    document.documentElement.style.setProperty('--vh',`${vh}px`);

    const debouncedHandlerResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
    }, 500); 

    window.addEventListener('resize', debouncedHandlerResize);
    
    return () => {
      window.removeEventListener('resize', debouncedHandlerResize);
    }
    
  });

  return (
    <div>
      <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content="Escuela Montessori, única en Melipilla. Atiende niños de 6 meses a 3 años en etapa preescolar y desde primero a octavo básico en edad escolar.
Enfocada en una educación libre y respetuosa de la invidualidad de cada niño." />
      </Helmet>
      <Header/>
      <div className="App">
        {routes.map(({path,Component}) => (
          <Route key={path} exact path={path} component={Component}>
            
            <Component/>
          </Route> 
        ))}

      </div>
      <Footer/>

      

    </div>
    
  );
}

export default App;
