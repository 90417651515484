import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter as Router} from 'react-router-dom';
import ScrollToTop from '../src/components/scrolltop';
//import TagManager from 'react-gtm-module';
//const tagManagerArgs = {
  // gtmId: 'G-B6S8PJXRPJ'
//}

//TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <Router>
    <ScrollToTop/>
    <App />
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
