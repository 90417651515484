import React from 'react'
// import { NavLink } from 'react-router-dom';




const Vitrina = (data) =>{
  let imgSrc =  data.bgImg;
  return (
    <div className="container-fluid cont-vitrina">
        <div className="row">
          <div 
            className="col-12 vitrina d-flex justify-content-center align-items-center align-content-center" 
          >
            <img 
              src={require(`../assets/vitrinas/${imgSrc}`)}
              alt={data.alt}
            />
          </div>
        </div>
    </div>
    
  )
}

export default Vitrina
