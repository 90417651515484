import React from 'react';
import Vitrina from '../components/vitrina';
import { Helmet } from 'react-helmet';
let imgVitrina = {
  // bgImg:'vitrina-home.png',
  bgImg:'vitrina-1.png',
  alt:'PlayHouse Montessori'
};
const Home = () => {
  return (
    <>

      <Vitrina 
        bgImg = {imgVitrina.bgImg}
        alt = {imgVitrina.alt}
      />
      <Helmet>
          <title>Home | PlayHouse Montessori</title>
      </Helmet>
      <div className="container-fluid mb-5">
          <div className="row">
              <div className="col-11 col-sm-10 col-md-8 col-xl-6 mx-auto cont-subTitle">
                  <h1 className="text-1 light text-center">
                    “Cuando un niño se siente seguro,&nbsp;
                    <br className="d-none d-md-inline-block"/>
                    deja de buscar aprobación a cada paso que da”
                    <br/>
                    <span className="text-right text semiBold float-right pt-3">
                      Maria Montessori.-
                    </span>
                  </h1>
              </div>
          </div>
      </div>
      <div className="container-fluid cont-box-info py-5">
        <div className="row">
            <div className="pattern pattern-top"></div>
            <div className="col-12 col-sm-4 d-flex flex-column align-items-center box-info bgc-green-dark">
                <div className="icon">
                  <img 
                    src={require('../assets/icons/i-estudiantes.svg')}
                    alt="" 
                  />
                </div>
                <div className="content">
                    <p className="text-1 semiBold text-center">Estudiantes</p>
                    <img 
                      src={require('../assets/icons/dots.svg')}
                      alt="" 
                    />
                    <p className="text-2 regular text-center">Aprende a través de la<br/>práctica activa con materiales<br/>del ambiente</p>
                </div>
            </div>
            <div className="col-12 col-sm-4 d-flex flex-column align-items-center box-info bgc-pink">
                <div className="icon">
                  <img 
                    src={require('../assets/icons/i-guia.svg')}
                    alt="" 
                  />
                  </div>
                <div className="content">
                    <p className="text-1 semiBold text-center">Guía</p>
                    <img 
                      src={require('../assets/icons/dots.svg')}
                      alt="" 
                    />
                    <p className="text-2 regular text-center">Actúa como un facilitador<br/>entre otros dos</p>
                </div>
            </div>
            <div className="col-12 col-sm-4 d-flex flex-column align-items-center box-info bgc-green">
                <div className="icon">
                  <img 
                    src={require('../assets/icons/i-ambiente.svg')}
                    alt="" 
                  />
                </div>
                <div className="content">
                    <p className="text-1 semiBold text-center">Ambiente</p>
                    <img 
                      src={require('../assets/icons/dots.svg')}
                      alt="" 
                    />
                    <p className="text-2 regular text-center">Provee conceptos<br/>a través de materiales</p>
                </div>
            </div>
            <div className="pattern pattern-bottom"></div>
        </div>
      </div>
    </>
  )
}

export default Home
